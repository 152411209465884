import React, { useEffect, useState } from "react";
import { environment, isProd } from "@utils/constants";
import { useLocation, useSearchParams } from "react-router-dom";

import AllPaymentsDisabledMessageBox from "components/MessageBox/AllPaymentsDisabledMessageBox";
import AmexPwpBanner from "components/AmexPwpBanner/AmexPwpBanner";
import CardPaymentsDisabled from "components/CardPaymentsDisabled/CardPaymentsDisabled";
import ComponentLoader from "components/Loading/ComponentLoader";
import ExpressCheckout from "components/ExpressCheckout/ExpressCheckout";
import FadeIn from "components/Loading/FadeIn";
import PaymentDetails from "components/PaymentDetails/PaymentDetails";
import Summary from "components/Summary/Summary";
import braintree from "braintree-web";
import { useAuth } from "context/AuthContext";
import { useCheck } from "context/CheckContext";
import useGetDisabledPaymentOptions from "hooks/useGetDisabledPaymentOptions";
import usePageTitle from "hooks/usePageTitle";

const Checkout = (): React.ReactElement => {
  const [clientInstance, setClientInstance] = useState<
    braintree.Client | undefined
  >();
  const [expressCheckoutClientInstance, setExpressCheckoutClientInstance] =
    useState<braintree.Client | undefined>();
  const { creditCard: creditCardPaymentDisable, all: allPaymentsDisabled } =
    useGetDisabledPaymentOptions();

  const [isVisible, setIsVisible] = useState<boolean>(false);

  usePageTitle("Checkout");

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { check, guestInstance, resyInstance } = useCheck();
  const { isAuthenticated } = useAuth();

  const [searchParams] = useSearchParams();
  const showAmexPwpBanner = searchParams.toString() === "AmexPwp=true";

  // Set instance for Express Checkout
  useEffect(() => {
    setExpressCheckoutClientInstance(guestInstance);
  }, [guestInstance]);

  // Set the instance for CC payment
  useEffect(() => {
    if (isAuthenticated) {
      setClientInstance(resyInstance);
    } else {
      setClientInstance(guestInstance);
    }
  }, [guestInstance, isAuthenticated, resyInstance]);

  useEffect(() => {
    if (isProd) {
      setIsVisible(!!clientInstance);
    } else {
      setIsVisible(true);
    }
  }, [clientInstance]);

  if (allPaymentsDisabled) {
    return <AllPaymentsDisabledMessageBox checkNumber={check?.number} />;
  }

  if (!isVisible) {
    return <ComponentLoader />;
  }

  return (
    <FadeIn isVisible={isVisible}>
      {showAmexPwpBanner && <AmexPwpBanner />}

      <Summary collapsible={true} />

      {creditCardPaymentDisable &&
        !environment.payments.creditCard.disabledWarning && (
        <CardPaymentsDisabled />
      )}

      {!showAmexPwpBanner && (
        <ExpressCheckout
          clientInstance={expressCheckoutClientInstance}
          creditCardPaymentDisable={creditCardPaymentDisable}
          showAmexPwp={!environment.payments.expressCheckout.disabledAmexPwp}
        />
      )}

      {!creditCardPaymentDisable && (
        <PaymentDetails clientInstance={clientInstance} />
      )}
    </FadeIn>
  );
};

export default Checkout;
