import * as Styled from "./AddPaymentMethod.styles";

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { PaymentMethodProps, useCheck } from "context/CheckContext";
import braintree, { HostedFields } from "braintree-web";

import BraintreeField from "components/BraintreeField/BraintreeField";
import Button from "components/Button/Button";
import { ErrorType } from "components/PaymentDetails/PaymentDetails";
import { NetworkError } from "@utils/api/errors";
import addPaymentMethod from "utils/addPaymentMethod";
import { colors } from "@theme/Themes";
import getCheckoutAmountRequestData from "@utils/getCheckoutAmountRequestData";
import { isDev } from "utils/constants";
import { isTruthy } from "@utils/truthy";
import trackGaEvent from "utils/trackGaEvent";
import useCookie from "hooks/useCookies";
import { useParams } from "react-router-dom";

interface AddPaymentMethodProps {
  clientInstance: any;
  error: ErrorType | undefined;
  onHandlePayment: () => void;
  setError: Dispatch<SetStateAction<ErrorType | undefined>>;
  setErrorMessage?: Dispatch<SetStateAction<string | undefined>>;
  setShowAddPaymentMethod: Dispatch<SetStateAction<boolean>>;
}

function AddPaymentMethod({
  clientInstance,
  error,
  onHandlePayment,
  setError,
  setErrorMessage,
  setShowAddPaymentMethod,
}: AddPaymentMethodProps) {
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState<
    HostedFields | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { paymentMethods, setPaymentMethods, setSelectedMethod } = useCheck();
  // 🚨 Disabled Resy Authentication as their Braintree vault is no longer available
  // const [authCookie, _] = useCookie("authToken");
  const [dataCollector, setDataCollector] = useState<braintree.DataCollector>();
  const [hostedFieldsCreated, setHostedFieldsCreated] =
    useState<boolean>(false);

  const [cardType, setCardType] = useState<string | undefined>();

  const { check, splitCheck, selectedTip } = useCheck();
  const { placeCode } = useParams();

  const showCancelButton = paymentMethods && paymentMethods?.length > 0;

  const createDataCollector = useCallback(async () => {
    if (clientInstance) {
      await braintree.dataCollector
        .create({ client: clientInstance, kount: true })
        .then((dataCollectorInstance) => {
          setDataCollector(dataCollectorInstance);
        })
        .catch((error) =>
          console.error("Error creating Data Collector instance", error)
        );
    }
  }, [clientInstance]);

  useEffect(() => {
    if (clientInstance && !hostedFieldsCreated) {
      createDataCollector().catch((error) =>
        console.error(
          "Add Payment Method > Failure creating Braintree DataCollector",
          error
        )
      );

      setHostedFieldsCreated(true);

      braintree.hostedFields.create(
        {
          client: clientInstance,
          styles: {
            body: {
              "background-color": `${colors.lighterBackground}`,
            },
            input: {
              "font-size": "14px",
              "background-color": `${colors.lighterBackground}`,
              color: colors.text,
            },
            ".valid": {
              color: "green",
            },
            ".invalid": {
              color: colors.error,
            },
          },
          fields: {
            number: {
              selector: "#cc-number",
              placeholder: isDev ? "4111 1111 1111 1111" : "Credit Card",
              prefill: undefined,
            },
            cvv: {
              selector: "#cc-cvv",
              placeholder: "123",
              prefill: undefined,
            },
            expirationDate: {
              selector: "#cc-expiration",
              placeholder: "MM / YY",
              prefill: undefined,
            },
            postalCode: {
              selector: "#cc-zip",
              placeholder: "Zip Code",
              prefill: undefined,
            },
          },
        },
        function (err, hfInstance) {
          // We don't need FE error thrown when hosted fields are already there
          if (err && err.code !== "HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME") {
            console.error(
              "Add Payment Method > Braintree Hosted Fields failure",
              err
            );
            return null;
          }

          setHostedFieldsInstance(hfInstance);
        }
      );
    }
  }, [
    clientInstance,
    createDataCollector,
    hostedFieldsCreated,
    hostedFieldsInstance,
  ]);

  const checkoutAmountRequest = getCheckoutAmountRequestData(
    check,
    splitCheck,
    selectedTip
  );

  const handleAddPaymentMethod = useCallback(
    async (nonce: string, placeCode: string) => {
      if (nonce) {
        try {
          const { paymentMethods: updatedPaymentMethods } =
            await addPaymentMethod({
              nonce,
              // resyAuthToken: authCookie,
              deviceData: dataCollector?.deviceData,
              placeCode,
            });

          setPaymentMethods(updatedPaymentMethods);

          const selected = updatedPaymentMethods.filter(
            (item: PaymentMethodProps) => item.isDefault
          )[0];

          setSelectedMethod(selected);
          setIsLoading(false);
          setShowAddPaymentMethod(false);

          if (!updatedPaymentMethods[0].amex) {
            onHandlePayment?.();
            return;
          }
        } catch (error) {
          setError("statusError");
          if (
            error instanceof NetworkError &&
            isTruthy(error.data?.userMessage)
          ) {
            setErrorMessage?.(error.data?.userMessage);
          }
          setIsLoading(false);
          setShowAddPaymentMethod(true);
        }
      }
    },
    [
      dataCollector?.deviceData,
      setPaymentMethods,
      setSelectedMethod,
      setShowAddPaymentMethod,
      onHandlePayment,
      setError,
      setErrorMessage,
    ]
  );

  const handleVerifyPayment = useCallback(() => {
    setError(undefined);
    setErrorMessage?.(undefined);
    setIsLoading(true);

    hostedFieldsInstance?.tokenize(function (err, instancePayload) {
      if (err) {
        console.error("Add Payment Method > Error Verifying payment", err);
        setError(err.details?.invalidFieldKeys ?? err?.code);
        setIsLoading(false);
        setShowAddPaymentMethod(true);

        trackGaEvent("Error: Verify Payment creating token", {
          description: `PlaceCode: ${placeCode} | CheckNumber: ${check?.number}`,
        });

        return;
      }

      if (instancePayload?.nonce && placeCode) {
        void handleAddPaymentMethod(instancePayload?.nonce, placeCode);
      }
    });

    trackGaEvent("Clicked Verify Payment", {
      description: `PlaceCode: ${placeCode} | CheckNumber: ${check?.number}`,
    });
  }, [
    check?.number,
    handleAddPaymentMethod,
    hostedFieldsInstance,
    placeCode,
    setError,
    setErrorMessage,
    setShowAddPaymentMethod,
  ]);

  const handleHidePaymentMethod = useCallback(() => {
    setShowAddPaymentMethod(false);
  }, [setShowAddPaymentMethod]);

  useEffect(() => {
    hostedFieldsInstance?.on("cardTypeChange", function (event) {
      if (event.cards.length === 1) {
        setCardType(event.cards[0].type);
      } else {
        setCardType(undefined);
      }
    });
  }, [hostedFieldsInstance]);

  return (
    <>
      <Styled.Form
        action="/"
        method="post"
        id="payment-form"
        onSubmit={handleVerifyPayment}
      >
        <Styled.Container direction="column">
          <BraintreeField
            error={error?.includes("number") ?? false}
            errorText="Enter a valid card number."
            id="cc-number"
            label="Card Number"
          />

          <Styled.Row>
            <BraintreeField
              error={error?.includes("expirationDate") ?? false}
              errorText="Enter a valid expiry date."
              id="cc-expiration"
              label="Expires"
            />
            <BraintreeField
              error={error?.includes("cvv") ?? false}
              errorText="Enter a valid CVV."
              id="cc-cvv"
              label="CVV"
            />
          </Styled.Row>

          <Styled.Container>
            <BraintreeField
              error={error?.includes("postalCode") ?? false}
              errorText="Enter a valid Zip Code."
              id="cc-zip"
              label="Zip Code"
            />
            <Styled.EmptyColumn />
          </Styled.Container>
        </Styled.Container>

        <Styled.Row>
          {showCancelButton && (
            <Button
              onClick={handleHidePaymentMethod}
              text="Cancel"
              variant="outlined"
            />
          )}
          <Button
            className="cta-button"
            isLoading={isLoading}
            onClick={handleVerifyPayment}
            text={
              cardType === "american-express" || cardType === "amex"
                ? "Verify Payment"
                : `Pay Check: ${checkoutAmountRequest.rooamTotalInCents
                  .toDollars()
                  .toCurrency()}`
            }
            type="submit"
          />
        </Styled.Row>
      </Styled.Form>
    </>
  );
}

export default AddPaymentMethod;
