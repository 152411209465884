import braintree, { Client } from "braintree-web";
import { useCallback, useEffect, useMemo, useState } from "react";

import Button from "components/Button/Button";
import { ErrorType } from "components/PaymentDetails/PaymentDetails";
import { ReactComponent as GoogleIcon } from "assets/google-play-icon.svg";
import checkoutProcessing from "utils/checkoutProcessing";
import getCheckoutAmountRequestData from "../../utils/getCheckoutAmountRequestData";
import trackGaEvent from "utils/trackGaEvent";
import { useCheck } from "context/CheckContext";
import { useNavigateToCompletePage } from "@hooks/useNavigateToCompletePage";
import { usePlace } from "context/PlaceContext";

interface GooglePayProps {
  clientInstance: Client | undefined;
  amount: string;
  onError: (error: React.ReactNode) => void;
  setError: React.Dispatch<React.SetStateAction<ErrorType | undefined>>;
}

const environment =
  process.env.NODE_ENV === "production" ? "PRODUCTION" : "TEST";
const googleMerchantId = process.env.REACT_APP_GOOGLE_MERCHANT_ID;

function GooglePay({
  clientInstance: client,
  amount,
  onError,
  setError,
}: GooglePayProps) {
  const [isProcessingCheckout, setIsProcessingCheckout] =
    useState<boolean>(false);
  const { check, selectedTip, splitCheck } = useCheck();
  const { place } = usePlace();
  const { toCompletePage } = useNavigateToCompletePage();

  const [clientReady, setClientReady] = useState<boolean>(false);
  const [supportsGooglePay, setSupportsGooglePay] = useState<boolean>(false);
  const [googlePayInstance, setGooglePayInstance] = useState<
    braintree.GooglePayment | undefined
  >(undefined);
  const [dataCollector, setDataCollector] = useState<braintree.DataCollector>();

  const checkoutAmountRequest = getCheckoutAmountRequestData(
    check,
    splitCheck,
    selectedTip
  );

  const paymentsClient = useMemo(
    () =>
      new google.payments.api.PaymentsClient({
        environment,
      }),
    []
  );

  const setCheckoutErrorState = useCallback(
    (error: any) => {
      if (
        error.statusCode === "CANCELED" ||
        error.code === "GOOGLE_PAYMENT_NOT_ENABLED"
      ) {
        return;
      }

      setError("checkoutError");
      setIsProcessingCheckout(false);

      onError(
        <>
          <strong>Error:</strong> There was an error processing your payment.
          Please try again or contact your server.
        </>
      );

      trackGaEvent("Error: GooglePay performCheckout", {
        description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
      });
    },
    [setError, onError, place?.code, check?.number]
  );

  const createGooglePayment = useCallback(async () => {
    await braintree.googlePayment
      .create({
        client,
        googlePayVersion: 2,
        googleMerchantId,
      })
      .then(async function (googlePaymentInstance) {
        setGooglePayInstance(googlePaymentInstance);
        setClientReady(true);

        return await paymentsClient.isReadyToPay({
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: (
            await googlePaymentInstance.createPaymentDataRequest()
          ).allowedPaymentMethods,
          existingPaymentMethodRequired: true,
        });
      })
      .then(function (isReadyToPay) {
        const { result } = isReadyToPay;

        if (result) {
          setSupportsGooglePay(result);
        }
      })
      .catch((err) => {
        console.error(
          "GooglePay > Error creating Braintree Payment instance",
          err
        );
        setCheckoutErrorState(err);
      });
  }, [client, paymentsClient, setCheckoutErrorState]);

  const createDataCollector = useCallback(async () => {
    if (client) {
      await braintree.dataCollector
        .create({ client, kount: true })
        .then((dataCollectorInstance) => {
          setDataCollector(dataCollectorInstance);
        })
        .catch((error) =>
          console.error(
            "GooglePay > Error creating Data Collector instance",
            error
          )
        );
    }
  }, [client]);

  useEffect(() => {
    if (!clientReady && googleMerchantId && client) {
      Promise.all([createGooglePayment(), createDataCollector()]).catch(
        (error) => console.error(error)
      );
    }
  }, [client, clientReady, createGooglePayment, createDataCollector]);

  const performCheckout = useCallback(
    async (googlePayNonce: string) => {
      if (!place?.code || !check?.id || !check?.number) {
        return;
      }

      const response = await checkoutProcessing({
        paymentMethod: "GOOGLE_PAY",
        placeCode: place.code,
        ticketId: check?.id,
        tipInCents: checkoutAmountRequest.tipInCents,
        amexPointsInDollarAmount: 0.0,
        nonce: googlePayNonce,
        deviceData: dataCollector?.deviceData,
        customAmountInCents: checkoutAmountRequest.customAmountInCents,
        ticketNumber: check?.number,
        splitCheckoutType: splitCheck?.splitCheckoutType,
      });

      if (response?.charged) {
        toCompletePage(checkoutAmountRequest, response);
      } else {
        setCheckoutErrorState(undefined);
      }
    },
    [
      place?.code,
      check?.id,
      check?.number,
      checkoutAmountRequest,
      dataCollector?.deviceData,
      splitCheck?.splitCheckoutType,
      toCompletePage,
      setCheckoutErrorState,
    ]
  );

  const handleGooglePayPayment = useCallback(async () => {
    const paymentDataRequest =
      await googlePayInstance?.createPaymentDataRequest({
        transactionInfo: {
          currencyCode: "USD",
          totalPriceStatus: "FINAL",
          totalPrice: amount,
        },
      });

    const cardPaymentMethod = paymentDataRequest?.allowedPaymentMethods[0];

    if (cardPaymentMethod) {
      cardPaymentMethod.parameters.billingAddressRequired = true;

      cardPaymentMethod.parameters.billingAddressParameters = {
        format: "FULL",
        phoneNumberRequired: true,
      };
    }

    if (paymentDataRequest && googlePayInstance) {
      paymentsClient
        .loadPaymentData(paymentDataRequest)
        .then(async function (paymentData) {
          return await googlePayInstance.parseResponse(paymentData);
        })
        .then((payload) => {
          performCheckout(payload.nonce)
            .then((_) => "Checkout Success")
            .catch((error) => {
              setIsProcessingCheckout(false);
              console.error("GooglePay > Checkout failed", error);
              setCheckoutErrorState(error);
            });
        })
        .catch((error) => {
          setIsProcessingCheckout(false);
          console.error("GooglePay >", error);
          setCheckoutErrorState(error);

          trackGaEvent("Error: GooglePay loadPaymentData", {
            description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
          });
        });
    }
  }, [
    amount,
    check?.number,
    googlePayInstance,
    paymentsClient,
    performCheckout,
    place?.code,
    setCheckoutErrorState,
  ]);

  const handleGooglePay = useCallback(() => {
    setError(undefined);
    setIsProcessingCheckout(true);
    void handleGooglePayPayment();

    trackGaEvent("Clicked GooglePay Button", {
      description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
    });
  }, [check?.number, handleGooglePayPayment, place?.code, setError]);

  if (!supportsGooglePay) {
    return <></>;
  }

  return (
    <Button
      ariaLabel="Pay with Google Pay"
      isLoading={isProcessingCheckout}
      className="expressCheckoutBtn"
      onClick={handleGooglePay}
      size="medium"
      node={
        <div className="google-pay-button-container">
          Pay with <GoogleIcon className="pay-icon" /> Pay
        </div>
      }
    />
  );
}

export default GooglePay;
